import 'bootswatch/dist/lux/bootstrap.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faCoffee } from '@fortawesome/free-solid-svg-icons'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-BR', ptBR)
setDefaultLocale('pt-BR')

library.add(faSpinner)
library.add(faCoffee)
