import React from 'react'
import styled, { css } from 'styled-components'

const Task = styled.div`
  color: black;
  ${ p => p.checked && css`
    span {
      text-decoration: line-through;
    }`
  }
`

const Subtasks = ({ tasks = [], taskId, onToggleTask }) => {
  if (!Array.isArray(tasks) || tasks.length === 0) return '' 
  
  return (
    <>
    { tasks.map((task, i) => (
      <Task checked={task.checked} key={i}>
        <input
          type='checkbox'
          checked={task.checked}
          onChange={onToggleTask(taskId, i)}
        />
        {' '}
        <span>{task.text}</span>
      </Task>
    ))}
    </>
  )
}

export default Subtasks
