import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'

class ModalHandler extends React.Component {

    state = { opened: false }

    handleOpen = () => {
        this.setState({ opened: true })
    }

    handleClose = () => {
        this.setState({ opened: false })
        if(this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        const { component, size, children, ...others } = this.props
        const Component = component
        return (
            <React.Fragment>
                {children({ open: this.handleOpen })}
                <Modal
                    size={size}
                    show={this.state.opened} 
                    onHide={this.handleClose}>
                    <Component 
                        {...others}
                        opened={this.state.opened}
                        open={this.handleOpen}
                        close={this.handleClose}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

export default ModalHandler