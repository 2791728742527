var cloneDate = function cloneDate(_date) {
  if (_date instanceof Date) {
    return new Date(_date.valueOf());
  }

  var myDate = new Date(_date.valueOf());
  var date = extractDate(myDate);
  var time = extractTime(myDate);
  return new Date("".concat(date, " ").concat(time));
};

var padZero = function padZero(n) {
  return n.toString().padStart(2, '0');
};

var extractDate = function extractDate(date) {
  return [date.getFullYear(), padZero(date.getMonth() + 1), padZero(date.getDate())].join('-');
};

var extractTime = function extractTime(date) {
  return [date.getHours(), date.getMinutes(), date.getSeconds()].join(':') + '.' + date.getMilliseconds();
};

module.exports = {
  cloneDate: cloneDate,
  extractDate: extractDate
};