import React, { useState } from 'react'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import { SessionProvider } from '../../common/session'
import { loadSession } from '../../common/auth'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'

import AppBar from '../app-bar'
import EstatisticasView from '../../views/estatisticas'
import LoginView from '../../views/login'
import PainelView from '../../views/painel'
import PlanejamentoView from '../../views/planejamento'
import ProjetosView from '../../views/projetos'
import UsuariosView from '../../views/usuarios'
import DisciplinasView from '../../views/disciplinas'
import EtapasView from '../../views/etapas'

const App = () => {
  const [session, setSession] = useState(loadSession())

  session.update = (value) => {
    setSession({
      ...session,
      ...value
    })
  }

  return (
    <SessionProvider value={session}>
      <BrowserRouter>
        <React.Fragment>
          <PrivateRoute path='/' component={AppBar} hide />
          <Switch>
            <PrivateRoute exact path='/' component={PainelView} />
            <PublicRoute exact path='/entrar' component={LoginView} />
            <PrivateRoute exact path='/estatisticas' component={EstatisticasView} />
            <PrivateRoute exact path='/planejamento' component={PlanejamentoView} />
            <PrivateRoute exact path='/projetos' component={ProjetosView} />
            <PrivateRoute exact path='/usuarios' component={UsuariosView} />
            <PrivateRoute exact path='/disciplinas' component={DisciplinasView} />
            <PrivateRoute exact path='/etapas' component={EtapasView} />
            <Redirect to='/' />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    </SessionProvider>
  )
}

export default App
