import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'

class RModal extends React.Component {

    state = { visible: false }

    onOpen = () => {
        this.setState({ visible: true })
    }

    onClose = () => {
        this.setState({ visible: false })
    }

    render() {
        const { children, component, size, ...others } = this.props
        const Component = component
        return (
            <React.Fragment>
                {children({ 
                  open: this.onOpen,
                  close: this.close
                })}
                <Modal
                  size={size || 'lg'}
                  show={this.state.visible} 
                  onHide={this.onCancel}>
                  <Modal.Header closeButton>
                  <Modal.Title>
                      Cadastrar Usuário
                  </Modal.Title>
              </Modal.Header>
                  <Component {...others} />
                </Modal>
            </React.Fragment>
        )
    }
}

export default RModal 