import { formatDate } from '../../common/helpers'

const baseDate = (dateString) => {
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

const gerarTasks = a => {
  const tasks = []

  if (a.status === 'finalizada') {
    tasks.push({
      from: a.dataInicialExecutada,
      to: a.dataFinalExecutada,
      title: a.descricao,
      style: { backgroundColor: 'green' }
    })
    if (a.previsaoFim) {
      tasks.push({
        from: a.dataInicialExecutada,
        to: a.previsaoFim,
        title: a.descricao,
        style: { backgroundColor: 'cornflowerblue' }
      })
    }
  }

  if (a.status === 'iniciada') {
    tasks.push({
      from: a.dataInicialExecutada,
      to: new Date(),
      title: a.descricao,
      style: { backgroundColor: 'coral' }
    })
    if (a.previsaoFim) {
      tasks.push({
        from: a.dataInicialExecutada,
        to: a.previsaoFim,
        title: a.descricao,
        style: { backgroundColor: 'cornflowerblue' }
      })
    }
  }

  if (a.status === 'nao-iniciada') {
    tasks.push({
      from: a.previsaoInicio,
      to: a.previsaoFim,
      title: a.descricao
    })
  }

  // if (a.dataInicialExecutada && a.dataFinalExecutada) {
  //   tasks.push({
  //     from: a.dataInicialExecutada,
  //     to: a.dataFinalExecutada,
  //     title: a.descricao,
  //     style: {
  //       backgroundColor: 'darkcyan'
  //     }
  //   })
  // }

  return tasks
}

const subtasksStatus = ({ subtasks }) => {
  if (!Array.isArray(subtasks) || subtasks.length === 0) return ''

  const completed = subtasks.reduce((acc, task) => task.checked ? ++acc : acc, 0)
  const total = subtasks.length

  return `${completed}/${total}`
}

export const preparar = (startDate, endDate, atividades, tipo = 'projeto', filtrar) => {
  if (!atividades) return null

  const data = {
    from: baseDate(startDate),
    to: baseDate(endDate)
  }

  data.rows = atividades
    .filter(a => !filtrar.coordenador || (a.coordenador && a.coordenador.exibicao === filtrar.coordenador))
    .filter(a => !filtrar.usuario || (a.usuario && a.usuario.exibicao === filtrar.usuario))
    .filter(a => !filtrar.projeto || (a.projeto && a.projeto.exibicao === filtrar.projeto))
    .map(a => ({
      group: a[tipo] ? a[tipo].exibicao : '---',
      data: a,
      column: {
        name: { name: a.descricao, width: 310 },
        escopo: { name: subtasksStatus(a), width: 40 },
        usuario: { name: tipo === 'projeto' ? a.usuario.exibicao : a.projeto.exibicao, width: 160 },
        iniciada: { name: formatDate(a.previsaoInicio || a.dataInicialExecutada), width: 90 },
        previsao: { name: formatDate(a.previsaoFim || a.dataFinalExecutada), width: 85 }
      },
      tasks: gerarTasks(a)
    }))
    .sort((_a, _b) => {
      const cA = _a.tasks[0]
      const cB = _b.tasks[0]
      const a = new Date(cA.from || cA.from)
      const b = new Date(cB.from || cB.from)
      return a - b
    })

  return data
}
