import React from 'react'
import ReactDatePicker from 'react-datepicker'
import styled from 'styled-components'

const DatePickerContent = styled.div`
  width: 100%;
  .react-datepicker-wrapper,
  .react-datepicker-wrapper div,
  .react-datepicker-wrapper input {
    width: 100%;
  }
  .react-datepicker-popper {
    z-index: 2;
  }
`

const castDate = (date) => (
  typeof date === 'string'
    ? new Date(date)
    : date
)

const DatePicker = ({ value, className, style, withTime, ...others }) => (
  <DatePickerContent
    style={style}>
    <ReactDatePicker
      showTimeInput={withTime}
      timeInputLabel='Horário: '
      className='form-control'
      selected={castDate(value)}
      dateFormat={withTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
      {...others} />
  </DatePickerContent>
)

export default DatePicker
