import React, { useContext } from 'react'
import SessionContext from '../../common/session'
import { Route, Redirect } from 'react-router-dom'
import { isValid, clear } from '../../common/auth'

const PrivateRoute = ({ component, hide, ...props }) => {
  const session = useContext(SessionContext)
  const Component = component
  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (!isValid(session) && !hide) {
          clear()
          return <Redirect to='/entrar' />
        }
        if (!isValid(session) && hide) {
          return null
        }
        return (
          <Component
            {...props}
            {...routeProps}
          />
        )
      }}
    />
  )
}

export default PrivateRoute
