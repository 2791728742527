var _toConsumableArray = require("/app/packages/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _slicedToArray = require("/app/packages/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var _defineProperty = require("/app/packages/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");

var _objectSpread = require("/app/packages/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");

var _require = require('../cloneDate'),
    extractDate = _require.extractDate,
    cloneDate = _require.cloneDate;

var _require2 = require('../period'),
    isMorning = _require2.isMorning,
    period = _require2.period;

var addWorkdays = require('../addWorkdays');

var STATE = {
  FINALIZADA: 'FINALIZADA',
  INICIADA: 'INICIADA',
  NAOINICIADA: 'NAO-INICIADA'
};

var initialTime = function initialTime(date) {
  return (isMorning(date) ? '00' : '12') + ':00:00.000';
};

var timestamp = function timestamp(dtString, tmString) {
  return "".concat(dtString, "T").concat(tmString, "-03:00");
};

var calcularIniciadas = function calcularIniciadas(atividades, lastDateForUser, today) {
  for (var i = 0; i < atividades.length; i++) {
    var atividade = atividades[i];
    var startedAt = cloneDate(new Date(atividade.dataInicialExecutada));
    var previsaoFim = addWorkdays(startedAt, atividade.estimativa);
    var lastDate = previsaoFim < today ? today : previsaoFim;
    atividade.previsaoFim = previsaoFim;
    lastDateForUser = extractLastDateByUser(atividade.usuarioId, lastDate, lastDateForUser, STATE.INICIADA);
  }

  return [atividades, lastDateForUser];
};

var preencherPrevisoes = function preencherPrevisoes(atividadesFinalizadas) {
  var currentDateForUser = {};

  for (var i = 0; i < atividadesFinalizadas.length; i++) {
    var atividade = atividadesFinalizadas[i];

    if (atividade.dataFinalExecutada) {
      currentDateForUser = extractLastDateByUser(atividade.usuarioId, atividade.dataFinalExecutada, currentDateForUser, STATE.FINALIZADA);
    }

    if (atividade.estimativa && atividade.estimativa > 0) {
      atividade.previsaoFim = addWorkdays(atividade.dataInicialExecutada, atividade.estimativa);
    }
  }

  return [atividadesFinalizadas, currentDateForUser];
};

var calcularNaoIniciadas = function calcularNaoIniciadas(today, naoIniciadas, lastDateForUser) {
  // Calcula as datas de inicio da primeira tarefa não inicializada
  var currentDateForUser = Object.keys(_objectSpread({}, lastDateForUser)).reduce(function (acc, uuid) {
    var lastDate = lastDateForUser[uuid].date;
    var lastState = lastDateForUser[uuid].state;

    if (lastState === STATE.FINALIZADA) {
      return _objectSpread({}, acc, _defineProperty({}, uuid, new Date(timestamp(extractDate(today), initialTime(today)))));
    }

    var initialDate = addWorkdays(lastDate < today ? today : lastDate, 1);
    var startDate = new Date(timestamp(extractDate(initialDate), initialTime(initialDate)));
    return _objectSpread({}, acc, _defineProperty({}, uuid, startDate));
  }, {}); // ordena as tarefaes por ordem de prioridade

  var ordenadas = naoIniciadas.sort(function (a, b) {
    return a.prioridade > b.prioridade ? 1 : -1;
  });

  for (var i = 0; i < ordenadas.length; i++) {
    var atividade = ordenadas[i]; // resgata a data onde este funcionário estará livre para executar a tarefa

    var currentDate = currentDateForUser[atividade.usuarioId] ? cloneDate(currentDateForUser[atividade.usuarioId]) : cloneDate(today); // Adiciona os campos previsao de início e fim baseados no dia em que o
    // funcionário estará livre estimativa para concluir a tarefa

    atividade.previsaoInicio = cloneDate(currentDate);
    currentDate = addWorkdays(currentDate, atividade.estimativa);
    atividade.previsaoFim = cloneDate(currentDate); // Se houver um deadline para a tarefa a quantidade de dias é calculada

    if (atividade.dataLimite !== null) {
      atividade.limite = calcularLimite(atividade.previsaoFim, atividade.dataLimite);
    } // O próximo dia em que este funcionário estará livre é calculado


    currentDate = addWorkdays(currentDate, 1);
    currentDateForUser[atividade.usuarioId] = new Date(timestamp(extractDate(currentDate), initialTime(currentDate)));
  }

  return ordenadas;
};

var calcularLimite = function calcularLimite(termino, limite) {
  return ((limite - termino) / (1000 * 60 * 60 * 24)).toFixed(1);
};

var extractLastDateByUser = function extractLastDateByUser(user, date, initialState, state) {
  var receivedDate = date instanceof Date ? date : new Date(date);
  var lastTime = (isMorning(receivedDate) ? '11' : '23') + ':59:59.999-03:00';
  var finalDate = new Date(extractDate(receivedDate) + ' ' + lastTime);

  if (!initialState.hasOwnProperty(user) || initialState.hasOwnProperty(user) && new Date(initialState[user].date) < finalDate) {
    return _objectSpread({}, initialState, _defineProperty({}, user, {
      date: finalDate,
      state: state
    }));
  }

  return initialState;
};

module.exports = function (atividades) {
  var today = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  var clonedAtvs = atividades.map(function (a) {
    return _objectSpread({}, a, {
      dataFinalExecutada: a.dataFinalExecutada ? new Date(a.dataFinalExecutada) : undefined,
      dataInicialExecutada: a.dataInicialExecutada ? new Date(a.dataInicialExecutada) : undefined,
      dataLimite: a.dataLimite ? new Date(a.dataLimite) : null
    });
  });

  var _finalizadas = clonedAtvs.filter(function (a) {
    return a.status === 'finalizada';
  });

  var _iniciadas = clonedAtvs.filter(function (a) {
    return a.status === 'iniciada';
  });

  var _naoIniciadas = clonedAtvs.filter(function (a) {
    return a.status === 'nao-iniciada';
  });

  var _preencherPrevisoes = preencherPrevisoes(_finalizadas),
      _preencherPrevisoes2 = _slicedToArray(_preencherPrevisoes, 2),
      finalizadas = _preencherPrevisoes2[0],
      currentDateForUser_finalizadas = _preencherPrevisoes2[1];

  var _calcularIniciadas = calcularIniciadas(_iniciadas, currentDateForUser_finalizadas, today),
      _calcularIniciadas2 = _slicedToArray(_calcularIniciadas, 2),
      iniciadas = _calcularIniciadas2[0],
      currentDateForUser_iniciadas = _calcularIniciadas2[1];

  var naoIniciadas = calcularNaoIniciadas(today, _naoIniciadas, currentDateForUser_iniciadas);
  return [].concat(_toConsumableArray(finalizadas), _toConsumableArray(iniciadas), _toConsumableArray(naoIniciadas));
};