import React, { useContext } from 'react'
import SessionContext from '../../common/session'
import { Route, Redirect } from 'react-router-dom'
import { isValid } from '../../common/auth'

const PublicRoute = ({ component, ...props }) => {
  const session = useContext(SessionContext)
  const Component = component
  return (
    <Route
      {...props}
      render={(routeProps) => {
        if (
          isValid(session) &&
          routeProps.location.pathname === '/entrar') {
          return (
            <Redirect to='/' />
          )
        }
        return (
          <Component
            {...props}
            {...routeProps}
          />
        )
      }}
    />
  )
}

export default PublicRoute
