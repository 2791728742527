import React from 'react'

export const SubNav = ({ children }) => (
  <div style={{
    paddingBottom: 16,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }}>
    {children}
  </div>
)

export { default as Modal } from './Modal'
