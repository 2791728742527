import React from 'react'
import Container from 'react-bootstrap/lib/Container'
import Form from 'react-bootstrap/lib/Form'
import Table from 'react-bootstrap/lib/Table'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import Button from 'react-bootstrap/lib/Button'
import { orderData } from '../../common/helpers'
import { SubNav } from '../../components'

import ModalHandler from '../../containers/modals/ModalHandler'
import ModalEtapa from './ModalEtapa'

export default ({
  etapas = [],
  refresh,
  onChange,
  onDesbloquear,
  onResetarSenha,
  toggleBlock
}) => {
  return (
    <Container style={{ paddingTop: 40 }}>
      <SubNav>
        <ModalHandler
          size='lg'
          component={ModalEtapa}
          onClose={refresh} >
          {({ open }) => (
            <Button
              onClick={open}
              style={{ marginRight: 8 }}>
              Novo
            </Button>
          )}
        </ModalHandler>
        <Form.Control
          style={{ maxWidth: 300 }}
          name='filterText'
          type='email'
          placeholder='Pesquisar...'
          onChange={onChange}
        />
      </SubNav>
      <Table striped bordered hover className='table-sm'>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Ordenação</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {etapas.map(etapa => (
            <tr
              className={etapa.bloqueado && 'table-danger'}
              key={etapa.id}
            >
              <td>{etapa.nome}</td>
              <td>{etapa.ordenacao}</td>
              <td>
                <ButtonGroup>
                  <ModalHandler
                    size='lg'
                    id={etapa.id}
                    component={ModalEtapa}
                    onClose={refresh} >
                    {({ open }) => (
                      <>
                        <Button
                          onClick={open}
                          variant='link'
                          size='sm'
                          style={{ padding: 4 }}>
                          Editar
                        </Button>
                        <Button
                          onClick={toggleBlock(etapa)}
                          variant='link'
                          size='sm'
                          style={{ padding: 4 }}>
                          {etapa.bloqueado ? 'Desbloquear' : 'Bloquear'}
                        </Button>
                      </>
                    )}
                  </ModalHandler>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
