import React from 'react'
import ReactDOM from 'react-dom'
import './assets'
import axios from 'axios'
import * as Sentry from '@sentry/browser'
import App from './modules/app'
import { version } from '../package.json'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
//const sentryDsn = process.env.SENTRY_DSN_REACT || null
const sentryDsn = "https://a8c98838ffdf4c58bfb8a2f7c3777e84@sentry.io/1814805"

if (sentryDsn) {
  Sentry.init({dsn: sentryDsn})
}

console.log('Version: %s', version)

ReactDOM.render(<App />, document.getElementById('root'))
