import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ModalAtividadeComponent from './ModalAtividadeComponent'

const ModalAtividade = ({
  id,
  close,
  refresh
}) => {
  const [projetos, setProjetos] = useState([])
  const [usuarios, setUsuarios] = useState([])
  const [disciplinas, setDisciplinas] = useState([])
  const [etapas, setEtapas] = useState([])
  const [coordenadores, setCoordenadores] = useState([])
  const [atividade, setAtividade] = useState({})
  const [currentTab, setTab] = useState(1)
  const [msgErro, setErrorMessage] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    axios.get(`/api/projetos`).then(response => setProjetos(response.data))
    axios.get(`/api/usuarios`, {
      params: {
        includeBlockeds: false
      }
    }).then(response => setUsuarios(response.data))
    axios.get(`/api/disciplinas`).then(response => setDisciplinas(response.data))
    axios.get(`/api/etapas`).then(response => setEtapas(response.data))
    axios.get(`/api/usuarios/coordenadores`).then(response => setCoordenadores(response.data))
    if (id) {
      axios.get(`/api/atividades/${id}`).then(response => {
        setAtividade(response.data)
      }).catch(setError)
    }
  }, [true])

  const onSubmit = async (e) => {
    if (!atividade.projetoId && !atividade.projeto) {
      setErrorMessage('Por favor, selecione o projeto.')
    } else if (!atividade.disciplinaId && !atividade.disciplina) {
      setErrorMessage('Por favor, selecione a disciplina.')
    } else if (!atividade.etapaId && !atividade.etapa) {
      setErrorMessage('Por favor, selecione a etapa.')
    } else if (!atividade.coordenadorId && !atividade.coordenador) {
      setErrorMessage('Por favor, selecione o coordenador.')
    } else if (!atividade.usuarioId && !atividade.usuario) {
      setErrorMessage('Por favor, selecione o responsável.')
    } else if (!atividade.descricao) {
      setErrorMessage('Por favor, preencha a descrição da tarefa.')
    } else if (!atividade.estimativa) {
      setErrorMessage('Por favor, informe os dias estimados para conclusão da atividade.')
    } else if (atividade.estimativa % 0.5 !== 0) {
      setErrorMessage('Estimativa inválida. Por favor, use períodos de meio dia (0.5) ou dias inteiros.')
    } else {
      if (id) {
        axios.post(`/api/atividades/${id}`, atividade).then(res => {
          refresh && refresh()
          close()
        }).catch(setError)
      } else {
        axios.post(`/api/atividades`, atividade).then(res => {
          refresh && refresh()
          close()
        }).catch(setError)
      }
    }
  }

  const onDelete = async () => {
    axios.delete(`/api/atividades/${id}`).then(res => {
      refresh && refresh()
      close()
    }).catch(setError)
  }

  const onChange = (e) => {
    setAtividade({
      ...atividade,
      [e.target.name]: e.target.value
    })
  }

  const onChangeProjeto = (e) => {
    setAtividade({
      ...atividade,
      projetoId: e.target.value
    })
  }

  const onChangeDisciplina = (e) => {
    setAtividade({
      ...atividade,
      disciplinaId: e.target.value
    })
  }

  const onChangeCoordenador = (e) => {
    setAtividade({
      ...atividade,
      coordenadorId: e.target.value
    })
  }

  const onChangeEtapa = (e) => {
    setAtividade({
      ...atividade,
      etapaId: e.target.value
    })
  }

  const onChangeUsuario = (e) => {
    setAtividade({
      ...atividade,
      usuarioId: e.target.value
    })
  }

  const onChangeDataLimite = (dataLimite) => {
    setAtividade({
      ...atividade,
      dataLimite
    })
  }

  const onChangeDataInicialExecutada = (dataInicialExecutada) => {
    setAtividade({
      ...atividade,
      dataInicialExecutada
    })
  }

  const onChangeDataFinalExecutada = (dataFinalExecutada) => {
    setAtividade({
      ...atividade,
      dataFinalExecutada
    })
  }

  const onChangeSubtasks = (subtasks) => {
    setAtividade({
      ...atividade,
      subtasks: subtasks
    })
  }

  if(error) {
    console.error(error)
  }

  return (
    <ModalAtividadeComponent
      {...atividade}
      projetos={projetos}
      usuarios={usuarios}
      disciplinas={disciplinas}
      etapas={etapas}
      coordenadores={coordenadores}
      msgErro={msgErro}
      currentTab={currentTab}
      setTab={setTab}
      onSubmit={onSubmit}
      onDelete={onDelete}
      onChange={onChange}
      onChangeProjeto={onChangeProjeto}
      onChangeDisciplina={onChangeDisciplina}
      onChangeCoordenador={onChangeCoordenador}
      onChangeEtapa={onChangeEtapa}
      onChangeUsuario={onChangeUsuario}
      onChangeDataLimite={onChangeDataLimite}
      onChangeDataInicialExecutada={onChangeDataInicialExecutada}
      onChangeDataFinalExecutada={onChangeDataFinalExecutada}
      onChangeSubtasks={onChangeSubtasks}
    />
  )
}

export default ModalAtividade
