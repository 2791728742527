import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import SessionContext from '../../common/session'
import PainelComponent from './components/PainelComponent'

const Painel = () => {
  const session = useContext(SessionContext)
  const [atual, setAtual] = useState({})
  const [proxima, setProxima] = useState({})
  const [finalizadas, setFinalizadas] = useState([])
  const [erradas, setErradas] = useState([])
  const [corrigir, setCorrigir] = useState([])
  const [error, setError] = useState(null)

  const loadAtual = async () => {
    const response = await axios.get('/api/atividades/atual')
    setAtual(response.data)
  }

  const loadProxima = async () => {
    const response = await axios.get('/api/atividades/proxima')
    setProxima(response.data)
  }

  const loadFinalizadas = async () => {
    const response = await axios.get('/api/atividades/finalizadas')
    setFinalizadas(response.data)
  }

  const loadErradas = async () => {
    const response = await axios.get('/api/atividades/erradas')
    setErradas(response.data)
  }

  const loadParaCorrigir = async () => {
    const response = await axios.get('/api/atividades/para-corrigir')
    setCorrigir(response.data)
  }

  const load = () => {
    Promise.all([
      loadAtual(),
      loadProxima(),
      loadFinalizadas(),
      loadErradas(),
      loadParaCorrigir()
    ]).catch(setError)
  }

  useEffect(load, [true])

  const onPausar = async (id) => {
    await axios.post(`/api/atividades/pausar/${id}`)
      .then(response => {
        return Promise.all([
          loadAtual(),
          loadFinalizadas(),
          loadProxima(),
          loadParaCorrigir()
        ])
      })
      .catch(setError)
  }

  const onIniciar = async (id) => {
    await axios.post(`/api/atividades/iniciar/${id}`)
      .then(response => {
        return Promise.all([
          loadAtual(),
          loadFinalizadas(),
          loadProxima()
        ])
      })
      .catch(setError)
  }

  const onToggleTask = (taskId, subtaskId) =>  async () => {
    await axios.post(`/api/atividades/toggle-task/${taskId}/${subtaskId}`, {})
      .then(response => {
        return Promise.all([
          loadAtual(),
          loadFinalizadas(),
          loadProxima()
        ])
      })
      .catch(setError)
  }

  if(error) {
    console.error(error)
  }

  return (
    <PainelComponent
      admin={session.usuario && session.usuario.admin}
      atual={atual}
      proxima={proxima}
      finalizadas={finalizadas}
      erradas={erradas}
      corrigir={corrigir}
      onToggleTask={onToggleTask}
      onPausar={onPausar}
      onIniciar={onIniciar}
      refresh={load} />
  )
}

export default Painel
