import React from 'react'
import Container from 'react-bootstrap/lib/Container'
import AtividadeAtual from './AtividadeAtual'
import ProximaAtividade from './ProximaAtividade'
import AtividadesFinalizadas from './AtividadesFinalizadas'
import AtividadesErradas from './AtividadesErradas'
import AtividadesVerificar from './AtividadesVerificar'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'

export default ({
  admin,
  atual, proxima, finalizadas, erradas, corrigir,
  onAprovar, onReprovar, onIniciar, refresh, onPausar, onToggleTask
}) => (
  <div>
    <Container style={{ paddingTop: 40, whiteSpace: 'pre-line' }}>
      <Row>
        <Col>
          <ProximaAtividade
            {...proxima}
            enableIniciar={!Object.keys(atual).length}
            atividade={proxima}
            onIniciar={onIniciar}
          />
        </Col>
        <Col>
          <AtividadeAtual
            {...atual}
            refresh={refresh}
            onToggleTask={onToggleTask}
            onPausar={onPausar}
          />
        </Col>

        {admin
          ? (
            <React.Fragment>
              <Col>
                <AtividadesErradas erradas={erradas} refresh={refresh} />
                <br />
                <AtividadesFinalizadas finalizadas={finalizadas} refresh={refresh} />
              </Col>
              <Col>
                <AtividadesVerificar verificar={corrigir} refresh={refresh} />
              </Col>
            </React.Fragment>
          )
          : (
            <React.Fragment>
              <Col>
                <AtividadesErradas erradas={erradas} refresh={refresh} />
              </Col>
              <Col>
                <AtividadesFinalizadas finalizadas={finalizadas} refresh={refresh} />
              </Col>
            </React.Fragment>
          )
        }

      </Row>
    </Container>
  </div>
)
