var period = function period(time) {
  if (!(time instanceof Date)) {
    throw new Error('Expect Date Instace');
  }

  return time.getHours() < 12 ? 'AM' : 'PM';
};

var isMorning = function isMorning(time) {
  return period(time) === 'AM';
};

var isEvening = function isEvening(time) {
  return period(time) === 'PM';
};

module.exports = {
  period: period,
  isMorning: isMorning,
  isEvening: isEvening
};