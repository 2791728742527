import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'

export const parseErrorMessage = err => {
  if (err.response && err.response.data) {
    return err.response.data.message
  } else {
    return err.message
  }
}

export const formatDateTime = date => {
  return dayjs(date).format('DD/MM/YYYY HH:mm')
}

export const formatDate = date => {
  return dayjs(date).format('DD/MM/YYYY')
}

export const orderData = (data, p) => {
  return data.sort(
    (a, b) => (a[p] > b[p])
      ? 1 : ((b[p] > a[p])
        ? -1 : 0))
}

export const filterData = (text, array, props) => {
  if (!text) return array
  if (!array) return []
  const filterText = text.toUpperCase()
  return array.filter(element =>
    props.some(
      m => element[m] && element[m].toUpperCase().indexOf(filterText) > -1
    )
  )
}
