import React from 'react'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'
import Button from 'react-bootstrap/lib/Button'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import Alert from 'react-bootstrap/lib/Alert'
import Modal from 'react-bootstrap/lib/Modal'
import Form from 'react-bootstrap/lib/Form'
import Tab from 'react-bootstrap/lib/Tab'
import Tabs from 'react-bootstrap/lib/Tabs'
import InputDateTime from '../../../components/elements/InputDate'
import styled from 'styled-components'
import Subtasks from './Subtasks'
import Attachments from './Attachment'

const Content = styled.div`
  padding: 20px 10px;
`

const progress = (tasks = []) => {
  if(!tasks || tasks.length === 0 || !Array.isArray(tasks)) return ''
  const completed = tasks.filter((t = {}) => t.checked).length
  const total = tasks.length
  return `${completed}/${total}`
}

export default ({
  currentTab,
  id,
  projetoId = '',
  usuarioId = '',
  disciplinaId = '',
  coordenadorId = '',
  etapaId = '',
  descricao,
  comentarios,
  escopo,
  estimativa,
  dataLimite,
  msgErro,
  projetos,
  disciplinas,
  coordenadores,
  etapas,
  usuarios,
  setTab,
  subtasks,
  dataInicialExecutada,
  onChangeDataInicialExecutada,
  dataFinalExecutada,
  onChangeDataFinalExecutada,
  onChangeProjeto,
  onChangeDisciplina,
  onChangeCoordenador,
  onChangeEtapa,
  onChange,
  onChangeUsuario,
  onChangeDataLimite,
  onChangeSubtasks,
  onSubmit,
  onDelete
}) => {
  return (
    <Form>
      <Modal.Header>
        <Modal.Title>Atividade</Modal.Title>
        {id && (
          <Button
            size='sm'
            className='pull-left'
            variant='danger'
            onClick={onDelete}>
            Excluir
          </Button>
        )}
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={currentTab} onSelect={setTab} >

          <Tab title='Informações' eventKey={1}>
            <Content>
              <Form.Group>
                <Form.Label>Projeto</Form.Label>
                <Form.Control
                  as='select'
                  value={projetoId}
                  onChange={onChangeProjeto}>
                  <option value=''>{`Selecione o projeto`}</option>
                  {(projetos || []).map((p, i) => (
                    <option key={i + 1} value={p.id}>{`${p.codigo} - ${p.exibicao}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Disciplina</Form.Label>
                <Form.Control
                  as='select'
                  value={disciplinaId}
                  onChange={onChangeDisciplina}>
                  <option key={0} value=''>{`Selecione a disciplina`}</option>
                  {(disciplinas || []).map((d, i) => (
                    <option key={i + 1} value={d.id}>{`${d.nome}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Etapa</Form.Label>
                <Form.Control
                  as='select'
                  value={etapaId}
                  onChange={onChangeEtapa}>
                  <option key={0} value=''>{`Selecione a etapa`}</option>
                  {(etapas || []).map((e, i) => (
                    <option key={i + 1} value={e.id}>{`${e.nome}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Coordenador</Form.Label>
                <Form.Control
                  as='select'
                  value={coordenadorId}
                  onChange={onChangeCoordenador}>
                  <option key={0} value=''>{`Selecione o coordenador`}</option>
                  {(coordenadores || []).map((c, i) => (
                    <option key={i + 1} value={c.id}>{`${c.nome}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button onClick={() => setTab(2)}>Continuar</Button>
            </Content>
          </Tab>

          <Tab title='Detalhamento' eventKey={2}>
            <Content>
              <Form.Group>
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  as='input'
                  type='text'
                  name='descricao'
                  value={descricao}
                  onChange={onChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Observações</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='3'
                  name='escopo'
                  value={escopo}
                  onChange={onChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Responsável</Form.Label>
                <Form.Control
                  as='select'
                  value={usuarioId}
                  onChange={onChangeUsuario}>
                  <option key={0} value=''>{`Selecione o responsável`}</option>
                  {(usuarios || []).map((u, i) => (
                    <option key={i + 1} value={u.id}>{`${u.exibicao}`}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              { id && <Attachments taskId={id} /> }
              <ButtonGroup>
                <Button onClick={() => setTab(3)}>Continuar</Button>
              </ButtonGroup>
            </Content>
          </Tab>

          <Tab title={'Escopo ' + progress(subtasks)} eventKey={3}>
            <Content>
              <Subtasks updateSubtasks={onChangeSubtasks} subtasks={subtasks || []} />
            </Content>
          </Tab>

          <Tab title='Previsões' eventKey={4}>
            <Content>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Estimativa (em dias):</Form.Label>
                    <Form.Control
                      as='input'
                      type='number'
                      step='0.5'
                      name='estimativa'
                      value={estimativa}
                      onChange={onChange} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Data inicial executada:</Form.Label>
                    <Form.Control
                      withTime
                      as={InputDateTime}
                      value={dataInicialExecutada}
                      onChange={onChangeDataInicialExecutada} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Data final executada:</Form.Label>
                    <Form.Control
                      withTime
                      as={InputDateTime}
                      value={dataFinalExecutada}
                      onChange={onChangeDataFinalExecutada} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Data limite de entrega:</Form.Label>
                    <Form.Control
                      as={InputDateTime}
                      value={dataLimite}
                      onChange={onChangeDataLimite} />
                  </Form.Group>
                </Col>
              </Row>
              <ButtonGroup>
                <Button onClick={onSubmit}>
                  {id ? 'Alterar' : 'Adicionar'}
                </Button>
              </ButtonGroup>
            </Content>
          </Tab>

          <Tab title='Histórico' eventKey={5}>
            <Content>
              <Form.Group>
                <Form.Label>Histórico</Form.Label>
                <Form.Control
                  as='textarea'
                  rows='8'
                  value={comentarios} />
              </Form.Group>
            </Content>
          </Tab>

        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        {msgErro && (
          <Alert variant='warning'>
            {msgErro}
          </Alert>
        )}
      </Modal.Footer>
    </Form>
  )
}
