import React from 'react'
import Container from 'react-bootstrap/lib/Container'
import Form from 'react-bootstrap/lib/Form'
import Table from 'react-bootstrap/lib/Table'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import Button from 'react-bootstrap/lib/Button'
import { SubNav } from '../../components'
import { orderData } from '../../common/helpers'

import ModalHandler from '../../containers/modals/ModalHandler'
import ModalProjeto from './ModalProjeto'

export default ({ projetos = [], onChange, refresh }) => (
  <Container style={{ paddingTop: 40 }}>
    <SubNav>
      <ModalHandler
        size='lg'
        onClose={refresh}
        component={ModalProjeto} >
        {({ open }) => (
          <Button
            onClick={open}
            style={{ marginRight: 8 }}>
            Cadastrar
          </Button>
        )}
      </ModalHandler>
      <Form.Control
        style={{ maxWidth: 300 }}
        name='filterText'
        type='email'
        placeholder='Pesquisar...'
        onChange={onChange} />
    </SubNav>
    <Table striped bordered hover className='table-sm'>
      <thead>
        <tr>
          <th>Código</th>
          <th>Exibição</th>
          <th>Arquivado</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {orderData(projetos, 'codigo').map((projeto, i) => (
          <tr key={i}>
            <td>{projeto.codigo}</td>
            <td>{projeto.cliente}</td>
            <td>{projeto.exibicao}</td>
            <td>
              <ButtonGroup>
                <ModalHandler
                  size='lg'
                  id={projeto.id}
                  onClose={refresh}
                  component={ModalProjeto} >
                  {({ open }) => (
                    <Button
                      onClick={open}
                      variant='link'
                      size='sm'
                      style={{ padding: 4 }}>
                      Editar
                    </Button>
                  )}
                </ModalHandler>
                {/* <Button style={{ padding: 4 }} variant='link' size='sm'>Arquivar</Button> */}
              </ButtonGroup>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </Container>
)
