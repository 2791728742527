import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/lib/Modal'
import Form from 'react-bootstrap/lib/Form'
import Button from 'react-bootstrap/lib/Button'
import { InlineError } from '../../containers/Errors'

const ModalUsuario = ({
  id,
  close
}) => {
  const [usuario, setUsuario] = useState({})
  const [error, setError] = useState(null)

  useEffect(() => {
    if (id) {
      axios.get(`/api/usuarios/${id}`).then(response => {
        setUsuario(response.data)
      }).catch(err => {
        setError(err)
      })
    }
  }, [true])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (id) {
      axios.post(`/api/usuarios/${id}`, usuario)
        .then(close)
        .catch(setError)
    } else {
      axios.post(`/api/usuarios`, usuario)
        .then(close)
        .catch(setError)
    }
  }

  const onChange = (e) => {
    setUsuario({
      ...usuario,
      [e.target.name]: e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Modal.Header>
        <Modal.Title>Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            name='nome'
            value={usuario.nome}
            onChange={onChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Sobrenome</Form.Label>
          <Form.Control
            name='sobrenome'
            value={usuario.sobrenome}
            onChange={onChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Nome de exibição</Form.Label>
          <Form.Control
            name='exibicao'
            value={usuario.exibicao}
            onChange={onChange} />
        </Form.Group>
        <Form.Group>
          <Form.Label>E-mail</Form.Label>
          <Form.Control
            name='email'
            value={usuario.email}
            onChange={onChange} />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type='checkbox'
            name='admin'
            label='Usuário administrador do sistema'
            checked={usuario.admin || false}
            onChange={onChange} />
        </Form.Group>
        <InlineError error={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Cancelar</Button>
        <Button type='submit' variant='success'>Confirmar</Button>
      </Modal.Footer>
    </Form>
  )
}

export default ModalUsuario
