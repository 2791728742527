import React from 'react'
import Button from 'react-bootstrap/lib/Button'
import Form from 'react-bootstrap/lib/Form'
import styled from 'styled-components'
import InputDate from '../../components/elements/InputDate'
import ModalHandler from '../../containers/modals/ModalHandler'
import ModalAtividade from './ModalAtividade'
import ModalPriorizacao from './ModalPriorizacao'

const ActionBar = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
padding-bottom: 10px;
align-items: center;
> * {
  margin-left: 4px;
}
`

const Actions = ({
  refresh,
  sortType, startDate, endDate,
  setSortType, setStartDate, setEndDate,
  coordenadores, setCoordenador, usuarios, setUsuario, projetos, setProjeto,
  coordenador, usuario, projeto,
  mostrarFiltros, setMostrarFiltros
}) => (
  <React.Fragment>
    <ActionBar>
      <Form.Control
        as={InputDate}
        value={startDate}
        style={{ width: '140px' }}
        onChange={setStartDate} />

      <Form.Control
        as={InputDate}
        value={endDate}
        style={{ width: '140px' }}
        onChange={setEndDate} />

      <Button
        variant='outline-primary'
        onClick={() => {
          setSortType(sortType === 'projeto' ? 'usuario' : 'projeto')
        }}>
        {sortType}
      </Button>

      <Button
        variant='outline-primary'
        onClick={() => {
          setMostrarFiltros(!mostrarFiltros)
        }}>
        {mostrarFiltros ? 'Esconder filtros' : 'Mostrar filtros'}
      </Button>

      <ModalHandler
        size='lg'
        onClose={refresh}
        component={ModalPriorizacao}>
        {({ open }) => (
          <Button
            onClick={open}>
          Priorizar
          </Button>
        )}
      </ModalHandler>

      <ModalHandler
        size='lg'
        onClose={refresh}
        component={ModalAtividade}>
        {({ open }) => (
          <Button
            onClick={open}>
          Cadastrar Atividade
          </Button>
        )}
      </ModalHandler>

    </ActionBar>

    {mostrarFiltros && (
      <ActionBar>
        <Form.Group>
          <Form.Control
            as='select'
            value={coordenador || ''}
            onChange={(e) => setCoordenador(e.target.value)}>
            <option value=''>Todos os coordenadores</option>
            {coordenadores.map((coordenador, i) =>
              <option key={i} value={coordenador}>{coordenador}</option>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            as='select'
            value={usuario || ''}
            onChange={(e) => setUsuario(e.target.value)}>
            <option value=''>Todos os usuários</option>
            {usuarios.map((usuario, i) =>
              <option key={i} value={usuario}>{usuario}</option>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            as='select'
            value={projeto || ''}
            onChange={(e) => setProjeto(e.target.value)}>
            <option value=''>Todos os projetos</option>
            {projetos.map((projeto, i) =>
              <option key={i} value={projeto}>{projeto}</option>
            )}
          </Form.Control>
        </Form.Group>
      </ActionBar>
    )}
  </React.Fragment>
)

export default Actions
