module.exports = function (a) {
  if (!a.usuarioId) {
    return 'nao-atribuida';
  } else if (a.usuarioId && !a.dataInicialExecutada) {
    return 'nao-iniciada';
  } else if (a.usuarioId && a.dataInicialExecutada && !a.dataFinalExecutada) {
    return 'iniciada';
  } else if (a.usuarioId && a.dataInicialExecutada && a.dataFinalExecutada) {
    return 'finalizada';
  } else {
    return null;
  }
};