import React from 'react'
import Container from 'react-bootstrap/lib/Container'
import Row from 'react-bootstrap/lib/Row'
import Col from 'react-bootstrap/lib/Col'
import Form from 'react-bootstrap/lib/Form'
import Button from 'react-bootstrap/lib/Button'
import Image from 'react-bootstrap/lib/Image'
import Alert from 'react-bootstrap/lib/Alert'
import logo from '../../assets/images/logo.png'

const LoginComponent = ({
  onSubmit, onChange, email, password, error
}) => (
  <Container>
    <Row>
      <Col md={{ span: 6, offset: 3 }}>
        <Form onSubmit={onSubmit} >
          <Image
            style={{
              marginLeft: -20,
              paddingTop: 40,
              paddingBottom: 20
            }}
            src={logo}
          />

          <h2>Entrar</h2>

          <Form.Group>
	    { error && <Alert variant='danger'>{ error }</Alert> }
            <Form.Label>Digite seu e-mail</Form.Label>
            <Form.Control
              name='email'
              type='email'
              placeholder='seu-nome@duallengenharia.com.br'
              value={email}
              onChange={onChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Senha</Form.Label>
            <Form.Control
              name='password'
              type='password'
              value={password}
              onChange={onChange}
            />
          </Form.Group>

          <Button type='submit'>Entrar</Button>

        </Form>
      </Col>
    </Row>
  </Container>
)

export default LoginComponent
