var _require = require('../cloneDate'),
    cloneDate = _require.cloneDate,
    extractDate = _require.extractDate;

var isWorkday = require('../isWorkday');

var _require2 = require('../period'),
    isMorning = _require2.isMorning,
    period = _require2.period;

var reachWeekend = require('../reachWeekend');

module.exports = function (date, days) {
  var currentDate = cloneDate(date); // o menor tamanho de tarefa é de meio dia, quantos meio dias existem no período passado

  var minPeriod = 0.5;
  var periods = days / minPeriod; // calcula a quantidade de milisegundos até o próximo período

  var oneDayInMs = 1000 * 60 * 60 * 24;
  var periodInMs = oneDayInMs * minPeriod;
  var weekendInMs = oneDayInMs * 2;
  var startDate = extractDate(currentDate);
  var startTime = (isMorning(currentDate) ? '00' : '12') + ':00:00.000-03:00';
  var startPoint = new Date(startDate + 'T' + startTime);
  var qttWeekends = reachWeekend(startPoint.getDay(), period(startPoint), days);
  return new Date(startPoint.getTime() + qttWeekends * weekendInMs + periods * periodInMs - 1);
};