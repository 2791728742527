import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DisciplinasComponent from './DisciplinasComponent'
import { filterData } from '../../common/helpers'

const Disciplinas = () => {
  const [disciplinas, setDisciplinas] = useState([])
  const [filterText, setFilterText] = useState('')
  const [error, setError] = useState(null)

  const load = () => {
    axios.get(`/api/disciplinas`, { params: { blocked: true } })
      .then(response => setDisciplinas(response.data))
      .catch(setError)
  }

  const toggleBlock = (disc) => () => {
    axios.put('/api/disciplinas', {
      ...disc,
      bloqueado: !disc.bloqueado
    }).then(load)
  }

  useEffect(load, [true])

  return (
    <DisciplinasComponent
      refresh={load}
      disciplinas={filterData(
        filterText,
        disciplinas,
        ['nome']
      )}
      onChange={(e) => { setFilterText(e.target.value) }}
      toggleBlock={toggleBlock}
      error={error} />
  )
}

export default Disciplinas
