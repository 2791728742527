import React, { useState } from 'react'
import axios from 'axios'
import FinalizarAtividadeComponent from './FinalizarAtividadeComponent'

const ModalFinalizarAtividade = ({
  id,
  comentarios,
  close,
  refresh
}) => {
  const [state, setState] = useState({
    id,
    novoComentarios: '',
    comentarios: comentarios || '',
    error: null
  })

  const onFinalizar = async (e) => {
    e.preventDefault()
    try {
      await axios.post(`/api/atividades/finalizar/${id}`, {
        comentarios: state.novoComentarios
      })
      refresh()
      close()
    } catch (error) {
      setState({ error })
    }
  }

  const onChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  return (
    <FinalizarAtividadeComponent
      {...state}
      onFinalizar={onFinalizar}
      onChange={onChange}
    />
  )
}

export default ModalFinalizarAtividade
