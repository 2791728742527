import React from 'react'
import Card from 'react-bootstrap/lib/Card'
import ListGroup from 'react-bootstrap/lib/ListGroup'
import { formatDateTime } from '../../../common/helpers'

const AtividadesFinalizadas = ({
  finalizadas
}) => (
  <Card>
    <Card.Body>
      <Card.Title>
        Realizadas
      </Card.Title>
      <Card.Subtitle className='mb-2'>
        Últimas atividades que você finalizou.
      </Card.Subtitle>
    </Card.Body>
    <ListGroup className='list-group-flush' style={{ maxHeight: 500, overflowX: 'auto' }}>
      {finalizadas && finalizadas.map((atividade, i) => (
        <ListGroup.Item key={i}>
          <small>{atividade.projeto.exibicao}</small><br />
          <span>{atividade.descricao}</span><br />
          <small>{formatDateTime(atividade.dataFinalExecutada)}</small>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Card>
)

export default AtividadesFinalizadas