import React from 'react'

const castError = (error) => {
    if(!error) return null
    const network = error.networkError
    //const graphQL = error.graphQLErrors
    if(network && network.result.errors && network.result.errors.length > 0) {
        return network.result.errors[0].message
    }
    return 'Erro inesperado.'
}

export const InlineError = ({ error }) => (
    <p className='text-danger'>{castError(error)}</p>
)