import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import Form from 'react-bootstrap/lib/Form'
import Button from 'react-bootstrap/lib/Button'
import { InlineError } from '../../Errors'

const FinalizarAtividadeComponent = (props) => (
  <Form onSubmit={props.onFinalizar}>
    <Modal.Header>
      <Modal.Title>Finalizar Atividade</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group controlId='formFinalizarAtividadeComentarios'>
        <Form.Label>Comentários</Form.Label>
        <Form.Control
          name='novoComentarios'
          as='textarea'
          rows='5'
          onChange={props.onChange}
        />
      </Form.Group>
      <Form.Group controlId='formCorrigirAtividadeHistorico'>
        <Form.Label>Histórico de revisões</Form.Label>
        <Form.Control
          as='textarea'
          rows='5'
          value={props.comentarios}
          readOnly
          disabled />
      </Form.Group>
      {props.error && (
        <InlineError error={props.error} />
      )}
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.close}>Cancelar</Button>
      <Button type='submit' variant='info'>Finalizar</Button>
    </Modal.Footer>
  </Form>
)

export default FinalizarAtividadeComponent
