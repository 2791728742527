import React, { useState, useEffect } from 'react'
import axios from 'axios'
import EtapasComponent from './EtapasComponent'
import { filterData } from '../../common/helpers'

const Etapas = () => {
  const [etapas, setEtapas] = useState([])
  const [filterText, setFilterText] = useState('')
  const [error, setError] = useState(null)

  const load = () => {
    axios.get(`/api/etapas`, { params: { blocked: true } })
      .then(response => setEtapas(response.data))
      .catch(setError)
  }

  const toggleBlock = (etapa) => () => {
    axios.put('/api/etapas', {
      ...etapa,
      bloqueado: !etapa.bloqueado
    }).then(load)
  }

  useEffect(load, [true])

  return (
    <EtapasComponent
      refresh={load}
      etapas={filterData(
        filterText,
        etapas,
        ['nome']
      )}
      onChange={(e) => { setFilterText(e.target.value) }}
      toggleBlock={toggleBlock}
      error={error} />
  )
}

export default Etapas
