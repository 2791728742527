import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import Container from 'react-bootstrap/lib/Container'
import { Gantt } from 'regantt'
import Actions from './Actions'
import ModalAtividade from './ModalAtividade'

const PlanejamentoComponent = ({
  data, refresh, updating, setUpdating, ...others
}) => (
  <Container fluid style={{ paddingTop: 20 }}>

    <Actions
      refresh={refresh}
      {...others} />

    <Modal
      size='lg'
      show={!!updating}
      onHide={() => setUpdating(null)}>
      <ModalAtividade
        id={updating}
        refresh={refresh}
        close={() => setUpdating(null)} />
    </Modal>

    <Gantt
      data={data}
      onHeaderTaskClick={(atividade) => {
        setUpdating(atividade.id)
      }}
    />
  </Container>
)

export default PlanejamentoComponent
