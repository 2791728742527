import React, { useEffect, useRef, useState } from 'react'
import Button from 'react-bootstrap/lib/Button'
import Form from 'react-bootstrap/lib/Form'
import styled from 'styled-components'
import axios from 'axios'

const AttachmentInput = styled.input`
  display: none;
`

const extractFieldAndValue = fields => key => {
  return [ key, fields[key] ]
}

const appendFieldInForm = form => ([key, value]) => form.append(key, value)

const getPreSignedForm = async (taskId, file) => {
  const { name, type } = file
  const data = await axios.get(`/api/file/tmp-upload-url?fileName=${taskId}/${name}&contentType=${type}`).then(({ data }) => data)
  return { file, data }
}

const uploadFile = ({ file, data }) => {
  const form = new FormData()

  Object.keys(data.fields)
    .map(extractFieldAndValue(data.fields))
    .forEach(appendFieldInForm(form))
  
  form.append('file', file)

  return fetch(data.url, {
    method: 'POST',
    body: form
  })

}

const getFilesFromTask = async (taskId) => {
  if (!taskId) return []
  const { data } = await axios.get(`/api/atividades/arquivo/${taskId}`)
  return data
}

const storeUploadedFiles = async (taskId, files, uploads) => {
  const success = files
    .map(file => file.file.name)
    .filter((name, idx) => uploads[idx].ok)

  const { data } = await axios.post(`/api/atividades/arquivo/${taskId}`, success)
  return data
}

export default ({ taskId }) => {
  const attachInput = useRef(null)
  const [ status, setStatus ] = useState('')
  const [ files, setFiles ] = useState([])

  useEffect(() => {
    if (status !== '' && !taskId) { return }
    getFilesFromTask(taskId)
      .then(setFiles)
    
  }, [ status, taskId ])

  const handleClick = () => {
    attachInput.current.click()
  }

  const handleFiles = async (e) => {
    const inpt = e.currentTarget

    setStatus('Subindo arquivos...')

    const files = await Promise.all(
      Array.from(inpt.files).map(file => getPreSignedForm(taskId, file))
    )

    const uploads = await Promise.all(
      files.map(uploadFile)
    )

    await storeUploadedFiles(taskId, files, uploads)

    setStatus('')
  }

  return (
    <Form.Group>
      <Form.Label>Anexos</Form.Label>
      <AttachmentInput onChange={handleFiles} ref={attachInput} type="file" multiple />
      <br />

      <ul>
        { files.map(({ file }, i) => (
          <li key={i}>{file}</li>
        )) }
      </ul>

      <Button onClick={handleClick} disabled={!!status}>
        { status ? status : 'Selecionar arquivos' }
      </Button>
    </Form.Group>
  )
}
