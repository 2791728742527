import React, { useState } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/lib/Modal'
import Form from 'react-bootstrap/lib/Form'
import Button from 'react-bootstrap/lib/Button'
import { InlineError } from '../Errors'

const ModalCorrigirAtividade = ({
  id,
  comentarios,
  close,
  refresh
}) => {
  const [novoComentarios, setNovoComentarios] = useState('')
  const [error, setError] = useState(null)

  const onCorrigir = async (e) => {
    e.preventDefault()
    try {
      await axios.post(`/api/atividades/corrigir/${id}`, {
        id, comentarios: novoComentarios
      })
      refresh()
      close()
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Form onSubmit={onCorrigir}>
      <Modal.Header>
        <Modal.Title>Corrigir Atividade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='formCorrigirAtividadeComentarios'>
          <Form.Label>Comentários</Form.Label>
          <Form.Control
            name='novoComentario'
            as='textarea'
            rows='3'
            onChange={e => setNovoComentarios(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId='formCorrigirAtividadeHistorico'>
          <Form.Label>Histórico de revisões</Form.Label>
          <Form.Control
            as='textarea'
            rows='3'
            value={comentarios}
            readOnly
            disabled />
        </Form.Group>
        <InlineError error={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Cancelar</Button>
        <Button variant='success' type='submit'>Corrigir</Button>
      </Modal.Footer>
    </Form>
  )
}

export default ModalCorrigirAtividade
