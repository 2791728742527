/* global localStorage */

import axios from 'axios'

export const register = (data, session) => {
  localStorage.setItem('sgd:session', JSON.stringify(data))
  axios.defaults.headers.authorization = 'Bearer ' + data.token
  session.update(data)
}

export const isValid = (session) => {
  if (!session || !session.exp) return null
  return (session.exp * 1000) > Date.now()
}

export const clear = (session) => {
  localStorage.clear('sgd:session')
  delete axios.defaults.headers.authorization
  if(session){
    session.update({exp: 0})
  }
}

export const loadSession = () => {
  const raw = localStorage.getItem('sgd:session')
  if (!raw) return {}
  const session = JSON.parse(raw)
  axios.defaults.headers.authorization = 'Bearer ' + session.token
  return session
}
