import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/lib/Button'
import Modal from 'react-bootstrap/lib/Modal'
import Form from 'react-bootstrap/lib/Form'
import styled from 'styled-components'
import axios from 'axios'

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;  
    align-items: center;
    padding: 10px;  
    background-color: whitesmoke;
    margin-bottom: 10px;
    height: 60px;
    span {
        flex-grow: 1;
        font-weight: 600;
        b {
          font-size: 15px;
        }
    }
`

const ModalPriorizacao = ({
  close
}) => {
  const [usuarioId, setUsuarioId] = useState('')
  const [atividades, setAtividades] = useState([])
  const [usuarios, setUsuarios] = useState([])
  const [loading, setLoading] = useState(null)

  const loadUsuarios = () => {
    axios.get(`/api/usuarios`)
      .then(response => setUsuarios(response.data))
  }

  const loadAtividades = () => {
    if (usuarioId !== '') {
      axios.get(`/api/atividades/nao-iniciadas/${usuarioId}`)
        .then(response => setAtividades(response.data))
    }
  }

  const priorizar = (id) => {
    setLoading(id)
    axios.post(`/api/atividades/priorizar/${id}`)
      .then(response => {
        loadAtividades()
        setLoading(null)
      })
  }

  useEffect(loadUsuarios, [true])

  useEffect(loadAtividades, [usuarioId])

  return (
    <Form>
      <Modal.Header>
        <Modal.Title>Priorização</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Content>

          <Form.Group>
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              as='select'
              value={usuarioId}
              onChange={(e) => setUsuarioId(e.target.value)}>
              <option value=''>Selecione o usuario</option>
              {usuarios.map((usuario, i) => (
                <option
                  key={i}
                  value={usuario.id}>
                  {usuario.exibicao}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {atividades.map((atividade, i) => (
            <Item>
              <span><b>{atividade.projeto && atividade.projeto.codigo}</b> - {atividade.descricao}</span>
              {i !== 0 && (
                <Button
                  size='sm'
                  disabled={loading === atividade.id}
                  onClick={() => priorizar(atividade.id)}>
                  {loading === atividade.id ? '...' : 'Priorizar'}
                </Button>
              )}
            </Item>
          ))}

        </Content>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Fechar</Button>
      </Modal.Footer>
    </Form>
  )
}

ModalPriorizacao.displayName = 'ModalPriorizacao'

export default ModalPriorizacao
