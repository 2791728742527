import React, { useState, useEffect } from 'react'
import axios from 'axios'
import UsuariosComponent from './UsuariosComponent'
import { filterData } from '../../common/helpers'

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([])
  const [filterText, setFilterText] = useState('')
  const [error, setError] = useState(null)

  const load = () => {
    axios.get(`/api/usuarios`)
      .then(response => setUsuarios(response.data))
      .catch(setError)
  }

  useEffect(load, [true])

  const onBloquear = async (data) => {
    axios.post(`/api/usuarios/bloquear/${data.id}`)
      .then(load)
      .catch(setError)
  }

  const onDesbloquear = async (data) => {
    axios.post(`/api/usuarios/desbloquear/${data.id}`)
      .then(load)
      .catch(setError)
  }

  const onResetarSenha = async (data) => {
    axios.post(`/api/usuarios/resetar-senha/${data.id}`)
      .then(load)
      .catch(setError)
  }

  return (
    <UsuariosComponent
      refresh={load}
      usuarios={filterData(
        filterText,
        usuarios,
        ['exibicao', 'email']
      )}
      onBloquear={onBloquear}
      onDesbloquear={onDesbloquear}
      onResetarSenha={onResetarSenha}
      onChange={(e) => { setFilterText(e.target.value) }}
      error={error} />
  )
}

export default Usuarios
