import React from 'react'
import { formatDate } from '../../../common/helpers'
import Card from 'react-bootstrap/lib/Card'
import ListGroup from 'react-bootstrap/lib/ListGroup'
import Button from 'react-bootstrap/lib/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const ProximaAtividade = ({
  id,
  descricao,
  projeto,
  dataInicialPlanejada,
  escopo,
  enableIniciar,
  onIniciar,
  atividade
}) => {
  if (!id) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>
          Próxima
          </Card.Title>
          <Card.Subtitle className='mb-2'>
          Sua próxima atividade
          </Card.Subtitle>
        </Card.Body>
      </Card>
    )
  }
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Próxima atividade
        </Card.Title>
        <Card.Subtitle className='mb-2'>
          Sua próxima atividade.
        </Card.Subtitle>
      </Card.Body>
      <ListGroup className='list-group-flush'>
        <ListGroup.Item>
          <small>Projeto:</small><br />
          <span style={{ color: 'black' }}>{projeto.exibicao}</span><br />

          <small>Descrição:</small><br />
          <span style={{ color: 'black' }}>{descricao}</span><br />

          <small>Iniciado em:</small><br />
          <span style={{ color: 'black' }}>{formatDate(new Date())}</span><br />
        </ListGroup.Item>
      </ListGroup>
      {enableIniciar && (
        <Card.Body>
          <Button
            variant='success'
            size='sm'
            onClick={(e) => {
              e.preventDefault()
              onIniciar(id)
            }}
            style={{ marginRight: 8 }}>
            Iniciar <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </Card.Body>
      )}
    </Card>
  )
}

export default ProximaAtividade
