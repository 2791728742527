import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause, faPaperclip, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/lib/Card'
import ListGroup from 'react-bootstrap/lib/ListGroup'
import Button from 'react-bootstrap/lib/Button'
import { formatDateTime, formatDate } from './../../../common/helpers'
import ModalHandler from '../../../containers/modals/ModalHandler'
import ModalFinalizar from './../../../containers/modals/FinalizarAtividade'
import Subtasks from './Subtasks'

const AtividadeAtual = ({
  id,
  descricao,
  escopo,
  projeto,
  dataInicialExecutada,
  previsaoFim,
  comentarios,
  files,
  refresh,
  onPausar,
  onToggleTask,
  subtasks,
  coordenador
}) => {
  if (!id) {
    return (
      <Card>
        <Card.Body>
          <Card.Title>
          Executando
          </Card.Title>
          <Card.Subtitle className='mb-2'>
          Atividade em andamento
          </Card.Subtitle>
        </Card.Body>
      </Card>
    )
  }
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Executando
        </Card.Title>
        <Card.Subtitle className='mb-2'>
          Atividade em andamento
        </Card.Subtitle>
      </Card.Body>
      <ListGroup className='list-group-flush'>
        <ListGroup.Item>
          <small>Projeto:</small><br />
          <span style={{ color: 'black' }}>{projeto.exibicao}</span><br />
          <small>Descrição:</small><br />
          <span style={{ color: 'black' }}>{descricao}</span><br />
          {escopo && (
            <React.Fragment>
              <small>Observações:</small><br />
              <span style={{ color: 'black' }}>{escopo}</span><br />
            </React.Fragment>
          )}
          <small>Escopo:</small><br />
          <Subtasks tasks={subtasks} taskId={id} onToggleTask={onToggleTask} />
          <small>Iniciado em:</small><br />
          <span style={{ color: 'black' }}>{formatDateTime(dataInicialExecutada)}</span><br />
          <small>Coordenador:</small><br />
          <span style={{ color: 'black' }}>{coordenador.exibicao}</span><br />
          <small>Término previsto:</small><br />
          {new Date() > new Date(previsaoFim) ? (
            <div>
              <span style={{ color: 'red' }}>{formatDate(previsaoFim)}</span><br />
            </div>
          ) : (
            <div>
              <span style={{ color: 'darkblue' }}>{formatDate(previsaoFim)}</span><br />
            </div>
          )}
        </ListGroup.Item>
        {files && files.length && (
          <ListGroup.Item>
            <small>Anexos:</small><br />
            {files.map(({name, url}, i) => (
              <React.Fragment key={i}>
                <FontAwesomeIcon icon={faPaperclip} color='lightgray' /> {' '}
                <a href={url}>{name}</a>
                <br />
              </React.Fragment>
            ))}
          </ListGroup.Item>
        )}
      </ListGroup>
      <Card.Body>
        <Button
          variant='warning'
          size='sm'
	  onClick={() => onPausar(id)}
          style={{ marginRight: 8 }}>
            Pausar &nbsp;<FontAwesomeIcon icon={faPause} />
        </Button>
        <ModalHandler
          size='lg'
          component={ModalFinalizar}
          id={id}
          comentarios={comentarios}
          refresh={refresh}>
          {({ open }) => (
            <Button
              variant='success'
              size='sm'
              onClick={open}
              style={{ marginRight: 8 }}>
                Finalizar <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          )}
        </ModalHandler>
      </Card.Body>
    </Card>
  )
}

export default AtividadeAtual
