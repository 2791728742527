import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { calcularPrevisoes } from '@duall/helpers'
import PlanejamentoComponent from './PlanejamentoComponent'
import { preparar } from './helpers'

const getDate = (val = 0) => {
  const date = new Date()
  date.setDate(date.getDate() + val)
  return date
}

const distinct = (data, tipo) => {
  return data
    .reduce((result, current) => {
      const key = current[tipo] ? current[tipo].exibicao : '---'
      if (result.indexOf(key) === -1) {
        return [...result, key]
      }
      return result
    }, [])
    .sort()
}

const Planejamento = () => {
  const [startDate, setStartDate] = useState(getDate(-10))
  const [endDate, setEndDate] = useState(getDate(40))
  const [atividades, setAtividades] = useState([])
  const [usuario, setUsuario] = useState(null)
  const [coordenador, setCoordenador] = useState(null)
  const [projeto, setProjeto] = useState(null)
  const [distinctUsuarios, setDistinctUsuarios] = useState([])
  const [distinctCoordenadores, setDistinctCoordenadores] = useState([])
  const [distinctProjetos, setDistinctProjetos] = useState([])
  const [updating, setUpdating] = useState(null)
  const [sortType, setSortType] = useState('projeto')
  const [mostrarFiltros, setMostrarFiltros] = useState(false)
  const [error, setError] = useState(null)

  const load = () => {
    axios.get(`/api/atividades?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`)
      .then(response => {
        const data = response.data
        setDistinctUsuarios(distinct(data, 'usuario'))
        setDistinctCoordenadores(distinct(data, 'coordenador'))
        setDistinctProjetos(distinct(data, 'projeto'))
        setAtividades(calcularPrevisoes(data))
      })
      .catch(setError)
  }

  useEffect(load, [startDate, endDate])

  const preparedData = preparar(startDate, endDate, atividades, sortType, {coordenador, usuario, projeto })

  return (
    <PlanejamentoComponent
      startDate={startDate}
      endDate={endDate}
      refresh={load}
      updating={updating}
      usuario={usuario}
      coordenador={coordenador}
      setUsuario={setUsuario}
      setCoordenador={setCoordenador}
      projeto={projeto}
      setProjeto={setProjeto}
      coordenadores={distinctCoordenadores}
      usuarios={distinctUsuarios}
      projetos={distinctProjetos}
      sortType={sortType}
      setUpdating={setUpdating}
      setSortType={setSortType}
      setStartDate={setStartDate}
      setEndDate={setEndDate}
      data={preparedData}
      mostrarFiltros={mostrarFiltros}
      setMostrarFiltros={setMostrarFiltros}
      error={error} />
  )
}

export default Planejamento
