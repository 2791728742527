import React, { useState, useEffect } from 'react'
import axios from 'axios'
import EstatisticasComponent from './EstatisticasComponent'

const baseStartDate = new Date()
baseStartDate.setDate(-30)

const EstatisticasContainer = () => {
  const [startDate, setStartDate] = useState(baseStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [usuario, setUsuario] = useState(null)
  const [usuarios, setUsuarios] = useState([])
  const [atividades, setAtividades] = useState([])
  const [error, setError] = useState(null)

  useEffect(() => {
    axios.get('/api/estatisticas/analitico', {
      params: {
        startDate, endDate, usuario
      }
    })
      .then(response => setAtividades(response.data))
      .catch(setError)
  }, [startDate, endDate, usuario])

  useEffect(() => {
    axios.get('/api/usuarios')
      .then(response => setUsuarios(response.data))
      .catch(setError)
  }, [true])

  return (
    <EstatisticasComponent
      atividades={atividades}
      startDate={startDate}
      endDate={endDate}
      usuario={usuario}
      usuarios={usuarios}
      setUsuario={u => setUsuario(u)}
      setUsuarios={setUsuarios}
      setStartDate={d => setStartDate(d)}
      setEndDate={d => setEndDate(d)}
      error={error}
    />
  )
}

export default EstatisticasContainer
