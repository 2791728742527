import React from 'react'
import styled from 'styled-components'
import Container from 'react-bootstrap/lib/Container'
import Form from 'react-bootstrap/lib/Form'
import Table from 'react-bootstrap/lib/Table'
import InputDate from '../../components/elements/InputDate'
import { formatDate, formatDateTime } from '../../common/helpers'

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  align-items: center;
  > * {
    margin-left: 4px;
  }
`

export default ({
  atividades,
  startDate,
  endDate,
  usuario,
  usuarios,
  setUsuario,
  setStartDate,
  setEndDate
}) => (
  <Container fluid style={{ paddingTop: 40 }}>

    <ActionBar>

      <Form.Control
        as={InputDate}
        value={startDate}
        style={{ width: '140px' }}
        onChange={setStartDate} />

      <Form.Control
        as={InputDate}
        value={endDate}
        style={{ width: '140px' }}
        onChange={setEndDate} />

      <div style={{ marginTop: 17 }}>
        <Form.Group >
          <Form.Control
            as='select'
            value={usuario ? usuario.id : ''}
            onChange={(e) => setUsuario(e.target.value)}>
            <option value=''>Todos os usuários</option>
            {usuarios.map((usuario, i) =>
              <option key={i} value={usuario.id}>{usuario.exibicao}</option>
            )}
          </Form.Control>
        </Form.Group>
      </div>

    </ActionBar>

    <Table striped bordered hover size='sm'>

      <thead>
        <tr>
          <th>Código</th>
          <th>Projeto</th>
          <th>Disciplina</th>
          <th>Etapa</th>
          <th>Usuário</th>
          <th>Atividade</th>
          <th>Inicio Executado</th>
          <th>Final Executado</th>
          <th>Estimativa</th>
          <th>Final Previsto</th>
          <th>Rep.</th>
        </tr>
      </thead>
      <tbody>
        {atividades.map(atividade => (
          <tr key={atividade.id}>
            <td>{atividade.projeto.codigo}</td>
            <td>{atividade.projeto.exibicao}</td>
            <td>{atividade.disciplina.nome}</td>
            <td>{atividade.etapa.nome}</td>
            <td>{atividade.usuario.exibicao}</td>
            <td>{atividade.descricao}</td>
            <td>{formatDateTime(atividade.dataInicialExecutada)}</td>
            <td>{formatDateTime(atividade.dataFinalExecutada)}</td>
            <td>{atividade.estimativa}</td>
            <td>{atividade.estimativa > 0 && formatDate(atividade.previsaoFim)}</td>
            <td>{atividade.reprovadoQnt}</td>
          </tr>
        ))}
      </tbody>
    </Table>

  </Container>
)
