import React from 'react'
import Container from 'react-bootstrap/lib/Container'
import Form from 'react-bootstrap/lib/Form'
import Table from 'react-bootstrap/lib/Table'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import Button from 'react-bootstrap/lib/Button'
import { orderData } from '../../common/helpers'
import { SubNav } from '../../components'

import ModalHandler from '../../containers/modals/ModalHandler'
import ModalUsuario from './ModalUsuario'

export default ({
  usuarios = [],
  refresh,
  onChange,
  onBloquear,
  onDesbloquear,
  onResetarSenha
}) => {
  const ordered = orderData(usuarios, 'exibicao')
  return (
    <Container style={{ paddingTop: 40 }}>
      <SubNav>
        <ModalHandler
          size='lg'
          component={ModalUsuario}
          onClose={refresh} >
          {({ open }) => (
            <Button
              onClick={open}
              style={{ marginRight: 8 }}>
              Convidar
            </Button>
          )}
        </ModalHandler>
        <Form.Control
          style={{ maxWidth: 300 }}
          name='filterText'
          type='email'
          placeholder='Pesquisar...'
          onChange={onChange}
        />
      </SubNav>
      <Table striped bordered hover className='table-sm'>
        <thead>
          <tr>
            <th>Exibição</th>
            <th>E-mail</th>
            <th>Tipo</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {ordered.map(usuario => (
            <tr key={usuario.id}>
              <td>{usuario.exibicao}</td>
              <td>{usuario.email}</td>
              <td>{usuario.admin ? 'Administrador' : 'Normal'}</td>
              <td>
                <ButtonGroup>
                  <ModalHandler
                    size='lg'
                    id={usuario.id}
                    component={ModalUsuario}
                    onClose={refresh} >
                    {({ open }) => (
                      <Button
                        onClick={open}
                        variant='link'
                        size='sm'
                        style={{ padding: 4 }}>
                        Editar
                      </Button>
                    )}
                  </ModalHandler>
                  {usuario.bloqueado ? (
                    <Button
                      style={{ padding: 4 }}
                      variant='link'
                      size='sm'
                      onClick={() => (onDesbloquear(usuario))}>
                      Desbloquear
                    </Button>
                  ) : (
                    <Button
                      style={{ padding: 4 }}
                      variant='link'
                      size='sm'
                      onClick={() => (onBloquear(usuario))}>
                      Bloquear
                    </Button>
                  )}
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
