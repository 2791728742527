import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/lib/Modal'
import Form from 'react-bootstrap/lib/Form'
import Button from 'react-bootstrap/lib/Button'
import { InlineError } from '../../containers/Errors'

const ModalDisciplina = ({
  id,
  close
}) => {
  const [disciplina, setDisciplina] = useState({})
  const [error, setError] = useState(null)

  useEffect(() => {
    if (id) {
      axios.get(`/api/disciplinas/${id}`).then(response => {
        setDisciplina(response.data)
      }).catch(err => {
        setError(err)
      })
    }
  }, [true])

  const onSubmit = async (e) => {
    e.preventDefault()
    if (id) {
      axios.put(`/api/disciplinas`, disciplina)
        .then(close)
        .catch(setError)
    } else {
      axios.post(`/api/disciplinas`, disciplina)
        .then(close)
        .catch(setError)
    }
  }

  const onChange = (e) => {
    setDisciplina({
      ...disciplina,
      [e.target.name]: e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value
    })
  }

  return (
    <Form onSubmit={onSubmit}>
      <Modal.Header>
        <Modal.Title>Disciplina</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            name='nome'
            value={disciplina.nome}
            onChange={onChange} />
        </Form.Group>
        <InlineError error={error} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={close}>Cancelar</Button>
        <Button type='submit' variant='success'>Confirmar</Button>
      </Modal.Footer>
    </Form>
  )
}

export default ModalDisciplina
