import React, { useState, useEffect } from 'react'
import axios from 'axios'
import ProjetosComponent from './ProjetosComponent'
import { filterData } from '../../common/helpers'

const Projetos = () => {
  const [projetos, setProjetos] = useState([])
  const [filterText, setFilterText] = useState('')
  const [error, setError] = useState(null)

  const load = () => {
    axios.get(`/api/projetos`)
      .then(response => setProjetos(response.data))
      .catch(setError)
  }

  useEffect(load, [true])

  return (
    <ProjetosComponent
      refresh={load}
      projetos={filterData(filterText, projetos, [
        'codigo',
        'exibicao',
        'cliente'
      ])}
      onChange={(e) => {
        setFilterText(e.target.value)
      }}
      error={error} />
  )
}

export default Projetos
