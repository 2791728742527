import React from 'react'
import Card from 'react-bootstrap/lib/Card'
import ListGroup from 'react-bootstrap/lib/ListGroup'
import Button from 'react-bootstrap/lib/Button'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'
import { formatDateTime } from '../../../common/helpers'
import ModalHandler from '../../../containers/modals/ModalHandler'
import ModalCorrigirAtividade from './../../../containers/modals/ModalVerificarAtividade'

const AtividadesVerificar = ({
  verificar,
  refresh
}) => (
  <Card>
    <Card.Body>
      <Card.Title>
        Necessário verificar
      </Card.Title>
      <Card.Subtitle className='mb-2'>
        Atividades que estão aguardando aprovação.
      </Card.Subtitle>
    </Card.Body>
    <ListGroup className='list-group-flush' style={{ maxHeight: 500, overflowX: 'auto', cursor: 'pointer' }}>
      {verificar && verificar.map((atividade, i) => (
        <ListGroup.Item key={i}>
          <small>Projeto:</small><br />
          <span style={{ color: 'black' }}>{atividade.projeto.exibicao}</span><br />
          <small>Descrição:</small><br />
          <span style={{ color: 'black' }}>{atividade.descricao}</span><br />
          <small>Iniciado em:</small><br />
          <span style={{ color: 'black' }}>{formatDateTime(atividade.dataInicialExecutada)}</span><br />
          <small>Finalizado em:</small><br />
          <span style={{ color: 'black' }}>{formatDateTime(atividade.dataFinalExecutada)}</span><br />
          <ButtonGroup style={{ paddingTop: 5, marginBottom: 5 }}>
            <ModalHandler
              size='lg'
              id={atividade.id}
              refresh={refresh}
              comentarios={atividade.comentarios}
              component={ModalCorrigirAtividade}>
              {({ open }) => (
                <Button
                  variant='info'
                  size='sm'
                  onClick={open}
                  style={{ marginRight: 8 }}>
                  Verificar
                </Button>
              )}
            </ModalHandler>
          </ButtonGroup>
        </ListGroup.Item>
      ))}
    </ListGroup>
  </Card>
)

export default AtividadesVerificar
