import React from 'react'
import { Button, InputGroup, Form } from 'react-bootstrap'

const addOne = (subtasks, updateSubtasks) => () => {
  return updateSubtasks([
    ...subtasks,
    { checked: false, text: '' }
  ])
}
const removeOne = (subtasks, updateSubtasks, id) => () => {
  const cloned = [...subtasks]
  return updateSubtasks(cloned.filter((_, i) => i !== id))
}

const handleChangeText = (subtasks, updateSubtasks, id) => ({ currentTarget }) => {
  const cloned = [...subtasks]
  const { value } = currentTarget
  updateSubtasks(cloned.map((item, i) => {
    return (i === id) ? { ...item, text: value } : { ...item }
  }))
}

const handleChangeCheck = (subtasks, updateSubtasks, id) => ({ currentTarget }) => {
  const cloned = [...subtasks]
  const { checked } = currentTarget
  updateSubtasks(cloned.map((item, i) => {
    return (i === id) ? { ...item, checked } : { ...item }
  }))
}

const Line = ({
  onClick,
  onFocus,
  checked = false,
  text,
  onChangeText,
  onChangeCheck,
  onRemove
}) => (
  <Form.Row className='mt-3'>
    <InputGroup>

      <InputGroup.Prepend>
        <InputGroup.Checkbox
          checked={checked}
          onChange={onChangeCheck}
        />
      </InputGroup.Prepend>

      <Form.Control
        type='text'
        onChange={onChangeText}
        value={text}
      />

      <InputGroup.Append>
        <Button onClick={onRemove}>-</Button>
      </InputGroup.Append>

    </InputGroup>
  </Form.Row>
)

export default ({ subtasks = [], updateSubtasks }) => {
  return (
    <Form>
      {Array.isArray(subtasks) && subtasks.map(({ checked, text }, i) => (
        <Line
          key={i}
          checked={checked}
          text={text}
          onChangeText={handleChangeText(subtasks, updateSubtasks, i)}
          onChangeCheck={handleChangeCheck(subtasks, updateSubtasks, i)}
          onRemove={removeOne(subtasks, updateSubtasks, i)}
        />
      ))}
      <Button className='mt-3' onClick={addOne(subtasks, updateSubtasks)}>+</Button>
    </Form>
  )
}
