var addWorkdays = require('./addWorkdays');

var calcularPrevisoes = require('./calcularPrevisoes');

var cloneDate = require('./cloneDate');

var isWorkday = require('./isWorkday');

var period = require('./period');

var preverFinal = require('./preverFinal');

var reachWeekend = require('./reachWeekend');

var validarStatus = require('./validarStatus');

module.exports = {
  addWorkdays: addWorkdays,
  calcularPrevisoes: calcularPrevisoes,
  cloneDate: cloneDate,
  isWorkday: isWorkday,
  period: period,
  preverFinal: preverFinal,
  reachWeekend: reachWeekend,
  validarStatus: validarStatus
};