import React, { useContext, useState } from 'react'
import axios from 'axios'
import { register } from '../../common/auth'
import SessionContext from '../../common/session'
import LoginComponent from './LoginComponent'

const Login = ({ history }) => {
  const session = useContext(SessionContext)
  const [state, setState] = useState({
    email: '',
    password: ''
  })
  const [error, setError] = useState(null)

  const onSubmit = async (e) => {
    try {
      e.preventDefault()
      const response = await axios.post('/api/authenticate', state)
      register(response.data, session)
      history.push('/')
    } catch (error) {
      setError(error.response.data.message)
    }
  }

  const onChange = (e, m) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  return (
    <LoginComponent
      {...state}
      error={error}
      onSubmit={onSubmit}
      onChange={onChange}
    />
  )
}

export default Login
