import React, { useContext } from 'react'
import SessionContext from '../../common/session'
import { clear } from '../../common/auth'
import { NavLink } from 'react-router-dom'
import Container from 'react-bootstrap/lib/Container'
import Navbar from 'react-bootstrap/lib/Navbar'
import Nav from 'react-bootstrap/lib/Nav'
import NavDropdown from 'react-bootstrap/lib/NavDropdown'
import Form from 'react-bootstrap/lib/Form'
import Button from 'react-bootstrap/lib/Button'

const NItem = ({ children, ...args }) => (
  <NavLink {...args}>
    <div className='nav-item'>{children}</div>
  </NavLink>
)

const AppBar = () => {
  const session = useContext(SessionContext)
  return (
    <Navbar bg='light' expand='lg'>
      <Container>
        <Navbar.Brand href='#home'>SGD</Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <Nav.Link exact as={NavLink} to='/'>Painel</Nav.Link>
            {session.usuario.admin && (
              <React.Fragment>
                <Nav.Link exact as={NavLink} to='/estatisticas'>Estatisticas</Nav.Link>
                <Nav.Link exact as={NavLink} to='/planejamento'>Planejamento</Nav.Link>
                <NavDropdown title='Sistema' id='basic-nav-dropdown'>
                  <NavDropdown.Item as={NItem} to='/disciplinas'>Disciplinas</NavDropdown.Item>
                  <NavDropdown.Item as={NItem} to='/etapas'>Etapas</NavDropdown.Item>
                  <NavDropdown.Item as={NItem} to='/projetos'>Projetos</NavDropdown.Item>
                  <NavDropdown.Item as={NItem} to='/usuarios'>Usuários</NavDropdown.Item>
                </NavDropdown>
              </React.Fragment>
            )}
          </Nav>
          <Form inline>
            <Button onClick={() => clear(session)} variant='outline-success'>Sair</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
export default AppBar
