import React from 'react'
import Card from 'react-bootstrap/lib/Card'
import ListGroup from 'react-bootstrap/lib/ListGroup'
import ModalHandler from '../../../containers/modals/ModalHandler'
import ModalCorrigirAtividade from './../../../containers/modals/ModalCorrigirAtividade'
import Button from 'react-bootstrap/lib/Button'
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup'

const AtividadesErradas = ({
  erradas,
  refresh
}) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          Necessário correções
        </Card.Title>
        <Card.Subtitle className='mb-2'>
          Atividades reprovadas, aguardando suas correções.
        </Card.Subtitle>
      </Card.Body>
      <ListGroup className='list-group-flush' style={{ maxHeight: 400, overflowX: 'auto', cursor: 'pointer' }}>
        {erradas && erradas.map((atividade, i) => (
          <ListGroup.Item key={i}>
            <small>Projeto:</small><br />
            <span style={{ color: 'black' }}>{atividade.projeto.exibicao}</span><br />
            <small>Descrição:</small><br />
            <span style={{ color: 'black' }}>{atividade.descricao}</span><br />
            <ButtonGroup style={{ paddingTop: 5, marginBottom: 5 }}>
              <ModalHandler
                size='lg'
                id={atividade.id}
                refresh={refresh}
                comentarios={atividade.comentarios}
                component={ModalCorrigirAtividade}>
                {({ open }) => (
                  <Button
                    variant='info'
                    size='sm'
                    onClick={open}
                    style={{ marginRight: 8 }}>
                Corrigir
                  </Button>
                )}
              </ModalHandler>
            </ButtonGroup>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Card>
  )
}

export default AtividadesErradas
